










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Profile extends Vue {
  @Prop({ required: true }) image!: string;
  @Prop({ required: true }) title!: string;
  @Prop({ default: false }) active!: boolean;
}
