import AppClient from '../AppClient';

import { UpdateUser, UpdatePassword } from './IProfile';

export default class ProfileService {
  private basePath = process.env.VUE_APP_AUTH_API;

  private Client = new AppClient({ url: this.basePath });

  async updateUser(data: UpdateUser) {
    const URI = '/update';

    const response = await this.Client.put(URI, data);

    return response;
  }

  async updatePassword(data: UpdatePassword) {
    const URI = '/password/update';

    const response = await this.Client.put(URI, data);

    return response;
  }

  async uploadImage(formData: FormData) {
    const basePath = `${process.env.VUE_APP_API_STATIC_CDN}`;
    const Client = new AppClient(
      { url: basePath },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    const URI = '/photos';
    const response = await Client.post(URI, formData);
    return response;
  }
}
