




































































import { Component, Vue, Prop } from 'vue-property-decorator';

import Checkbox from '@/components/Input/Checkbox/index.vue';

@Component({
  components: {
    Checkbox
  }
})
export default class ConsentTerms extends Vue {
  @Prop() data!: Record<string, any>;

  async setCheckbox(name: string, checked: boolean) {
    this.$emit('setCheckbox', {
      name,
      checked: !checked
    });
  }
}
