






import { Component, Vue, Prop } from 'vue-property-decorator';

const TEXT = 'Campo obrigatório!';

@Component({})
export default class FeedbackRequiredField extends Vue {
  @Prop({ default: TEXT }) text!: string;
}
