
































import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import DataFilters from '@/components/DataFilters/index.vue';
import BarChart from '@/components/Graphics/BarChart.vue';
import ExLoading from '@/globalComponents/ExLoading/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import {
  currentMonth, currentYear, getDaysArray, daysArray 
} from '@/share/Util/Dates/index';

import { Stats } from '../../interface';

let childCountGlobal = 0;

@Component({
  components: {
    DataFilters,
    BarChart,
    ExLoading,
    FeedbackUser
  }
})
export default class ContainerGraphic extends Vue {
  @Prop() context!: string;
  @Prop() textTitleGraphic!: string;
  @Prop() textTooltip!: string;
  @Prop() month!: number;
  @Prop() year!: number;
  @Prop() data!: Stats;
  @Prop({ default: true }) isLoading!: boolean;

  private selectedMonth = Number(currentMonth);
  private selectedYear = Number(currentYear);
  private daysArray = daysArray;
  private childKey = 1;
  private parentCounter = 0;

  created() {
    this.saveLastFilteredDate();
  }

  get titleGraphic() {
    return `${this.textTitleGraphic} ${this.data?.total}`;
  }

  get chartKey() {
    return `${this.childKey} - ${this.parentCounter}`;
  }

  @Watch('selectedMonth')
  @Watch('selectedYear')
  getChangeValuesSelects() {
    if (this.selectedMonth > 0 && this.selectedYear > 0) {
      this.getDays();
      this.updateChartData();
      this.emitSelectedDate();
    }
  }

  getDays() {
    this.daysArray = getDaysArray(this.selectedYear, this.selectedMonth);
  }

  updateChartData() {
    this.childKey += 1;
    childCountGlobal += 1;
    this.parentCounter = childCountGlobal;
  }

  emitSelectedDate() {
    this.$emit('get-data-by-context', {
      context: this.context,
      month: this.selectedMonth,
      year: this.selectedYear
    });
  }

  getDataGraphic() {
    const listData = Object.keys(this.data?.items);
    const listQuantitiesPerDay: Array<number> = [];

    listData.sort((a: string, b: string) => new Date(a).getTime() - new Date(b).getTime());

    listData.forEach((data: string) => {
      listQuantitiesPerDay.push(this.data?.items[data]);
    });

    return listQuantitiesPerDay;
  }

  getSelectedMonth(month: string) {
    if (Number(month) === this.selectedMonth) return;

    this.selectedMonth = Number(month);
  }

  getSelectedYear(year: number) {
    if (year === this.selectedYear) return;

    this.selectedYear = year;
  }

  formattedMonthForFilter() {
    return this.selectedMonth < 10 ? `0${this.selectedMonth}` : `${this.selectedMonth}`;
  }

  saveLastFilteredDate() {
    this.selectedMonth = this.month || this.selectedMonth;
    this.selectedYear = this.year || this.selectedYear;
  }
}
