




















import {
  Component, Prop, Vue, Watch 
} from 'vue-property-decorator';

import { DEFAULT_USERS } from '@/constant/AvatarsExp';

import { setImageProfile } from '@/share/Util/Image/Image';

@Component
export default class Thumb extends Vue {
  @Prop({ default: DEFAULT_USERS }) photo!: string;
  @Prop() background!: string;
  @Prop() title!: string;
  @Prop() setWidth!: number;
  @Prop({ default: 'thumb' }) alt!: string;

  private width = 30;
  private DEFAULT_USERS = DEFAULT_USERS;

  mounted() {
    this.resize();
  }

  get getStyleContent() {
    let background = '';

    if (this.background) background = `background: ${this.background}`;

    return `
      height: ${Math.ceil(this.width)}px;
      ${background}
    `;
  }

  @Watch('$responsive.contentWidth')
  @Watch('setWidth')
  resize() {
    this.width = Math.ceil(this.$el.clientWidth);
  }

  setImageProfile(image: string) {
    return setImageProfile(image);
  }
}
