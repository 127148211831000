




















































import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import VueImageCropUpload from 'vue-image-crop-upload/upload-2.vue';

import { LIST_AVATAR } from '../../constants/listAvatar';

import ProfileService from '@/services/Profile/ProfileService';

import { UrlTreatmentSaveVersa } from '@/share/Util/Image/Image';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

@Component({
  components: {
    VueImageCropUpload
  }
})
export default class PhotoProfile extends Vue {
  @Prop({ default: '' }) avatar!: string;
  @Prop() isSaveImage!: number;

  private fileURL = '';

  private show = false;

  private listAvatar = LIST_AVATAR;

  private ProfileService = new ProfileService();

  @Watch('isSaveImage')
  setFileURL() {
    this.fileURL = this.isSaveImage ? '' : this.fileURL;
  }

  showCropUploadImage() {
    this.show = true;
  }

  async cropSuccess(imgDataUrl: any) {
    this.handleImageAdded(this.DataURIToBlob(imgDataUrl));
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  async handleImageAdded(file: any) {
    try {
      const formData = new FormData();
      formData.append('file', file, 'profile.jpg');

      const response = await this.ProfileService.uploadImage(formData);

      if (response.status === STATUS_RESPONSE_API.SUCCESS_OK) {
        this.$store.dispatch('Toast/setToast', {
          text: 'Imagem carregada com sucesso!',
          status: 'success'
        });

        this.fileURL = response.data.data.url;
        this.changeAvatar(UrlTreatmentSaveVersa(this.fileURL));
      }
    } catch (error) {
      console.error(error);
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a imagem.',
        status: 'error'
      });
    }
  }

  setAvatar(id: number) {
    this.listAvatar.forEach((avatar: Record<string, any>, index: number) => {
      if (avatar.id === id) {
        this.listAvatar[index].select = true;
        this.changeAvatar(this.listAvatar[index].image);
        return;
      }
      this.listAvatar[index].select = false;
    });
  }

  changeAvatar(avatar: string) {
    this.$emit('changeAvatar', avatar);
  }
}
