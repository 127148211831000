export const LIST_AVATAR = [
  {
    id: 0,
    image: 'https://cdn.explicae.com.br/public/platform/avatar/avatar.jpg',
    select: false
  },
  {
    id: 1,
    image: 'https://static.explicae.com.br/avatars/1644331373855.png',
    select: false
  },
  {
    id: 2,
    image: 'https://static.explicae.com.br/avatars/1644331419361.png',
    select: false
  },
  {
    id: 3,
    image: 'https://static.explicae.com.br/avatars/1644350597659.png',
    select: false
  }
];
