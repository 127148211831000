









import { Component, Vue, Prop } from 'vue-property-decorator';

const DEFAULT_PROBLEM_TEXT = 'Está com algum problema com o seu plano?';
const DEFAULT_CTA_TEXT = 'Fale com o nosso atendimento';
const DEFAULT_PHONE = 557981093809;

@Component({})
export default class CustomerService extends Vue {
  @Prop({ default: DEFAULT_PROBLEM_TEXT }) problemText!: string;
  @Prop({ default: DEFAULT_CTA_TEXT }) CTAText!: string;
  @Prop({ default: DEFAULT_PHONE }) phone!: number;

  get link() {
    return `https://api.whatsapp.com/send?phone=${this.phone}&text=Oi,%20estou%20com%20problema%20no%20meu%20plano`;
  }
}
