














import { Component, Vue, Watch } from 'vue-property-decorator';

import { MENU_PROFILE, MENU_B2B, DEFINITION_NAME_TEACHER_B2B } from '../../constants/index';

@Component({})
export default class Profile extends Vue {
  private isActiveMenu = this.$store.getters.tabProfile;

  get isB2b() {
    return !!this.$store.getters.profile.institution?.name;
  }

  get isTeacher() {
    return this.$store.getters.profile?.definitioName === DEFINITION_NAME_TEACHER_B2B;
  }

  get menu() {
    if (this.isB2b) {
      return this.isTeacher ? this.menuWithoutMyClass : MENU_PROFILE;
    }

    return this.menuWithoutMyClass;
  }

  get menuWithoutMyClass() {
    return MENU_PROFILE.filter((menu: string) => !MENU_B2B.includes(menu));
  }

  isOpen(value: number): void {
    this.$store.commit('setTabProfile', value);
  }

  @Watch('menu', {
    immediate: true
  })
  saveMenuToStore() {
    this.$store.commit('setProfileMenu', this.menu);
  }

  @Watch('$store.getters.tabProfile')
  setIsActiveMenu() {
    this.isActiveMenu = this.$store.getters.tabProfile;
  }
}
