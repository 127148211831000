



















import { Component, Vue } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';

import ListUsers from './components/ListUsers/index.vue';

import InstitutionService from '@/services/Institution/InstitutionService';

interface ClassData {
  level: string;
  shift: string;
  year: string;
  degreeID: number;
  degreeName: string;
}

@Component({
  components: {
    ExLoading,
    ListUsers
  }
})
export default class MyClass extends Vue {
  private isLoading = true;
  private title = '';

  private institutionService = new InstitutionService();

  created() {
    this.getClassData();
  }

  async getClassData() {
    try {
      this.isLoading = true;

      const classData = await this.institutionService.getClassData();

      this.setTitle(classData);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as informações da turma',
        status: 'error'
      });
    } finally {
      this.isLoading = false;
    }
  }

  setTitle(classData: ClassData) {
    const shift = classData.shift.charAt(0).toUpperCase() + classData.shift.slice(1).toLowerCase();
    const level = classData.level.match(/\d+/);
    const levelNumber = level ? parseInt(level[0], 10) : null;

    this.title = `${levelNumber}° ano - ${classData.degreeName} - ${shift}`;
  }
}
