


















import { Component, Prop, Vue } from 'vue-property-decorator';

interface TableHeader {
  title: string;
}

@Component({})
export default class DataTable extends Vue {
  @Prop({ required: true }) tableHeader!: Array<TableHeader>;
}
