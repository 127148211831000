




































import { Component, Vue } from 'vue-property-decorator';

import ListUsersLoading from './ListUsersLoading.vue';

import HeaderListUsers from '../HeaderListUsers/index.vue';

import Thumb from '@/components/Thumb/index.vue';
import ErrorLoading from '@/components/ErrorLoading/ErrorLoading.vue';

import { Teacher, MyClassUser } from './interface';

import InstitutionService from '@/services/Institution/InstitutionService';

const tabTeachers = 1;

@Component({
  components: {
    ListUsersLoading,
    HeaderListUsers,
    Thumb,
    ErrorLoading
  }
})
export default class ListUsers extends Vue {
  private currentUserList: Array<MyClassUser | Teacher> = [];
  private myClass: Array<MyClassUser> = [];
  private myTeachers: Array<Teacher> = [];
  private showingStudents = true;
  private errorMyClass = false;
  private errorMyTeachers = false;
  private isLoadingMyClass = true;
  private isLoadingMyTeachers = true;
  private currentClassPage = 1;
  private currentTeachersPage = 1;
  private totalClassUsers = 0;
  private totalTeachers = 0;

  private institutionService = new InstitutionService();

  async created() {
    await this.getMyClass();
    this.getMyTeachers();
    this.currentUserList = this.myClass;
  }

  get isLoading() {
    return this.showingStudents ? this.isLoadingMyClass : this.isLoadingMyTeachers;
  }

  get userName() {
    return this.$store.getters.profile.name;
  }

  get avatar() {
    return this.$store.getters.avatar;
  }

  get hasMoreUsers() {
    if (this.showingStudents) return this.myClass.length < this.totalClassUsers;

    return this.myTeachers.length < this.totalTeachers;
  }

  get showFeedbackError() {
    return (
      (this.showingStudents && this.errorMyClass) || (!this.showingStudents && this.errorMyTeachers)
    );
  }

  async getMyClass() {
    try {
      this.isLoadingMyClass = true;

      const myClass = await this.institutionService.getMyClass(this.currentClassPage);

      this.myClass.push(...myClass?.students);

      this.totalClassUsers = myClass?.total;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a turma.',
        status: 'error'
      });

      this.errorMyClass = true;

      console.error(error);
    } finally {
      this.isLoadingMyClass = false;
    }
  }

  async getMyTeachers() {
    try {
      this.isLoadingMyTeachers = true;

      const myTeachers = await this.institutionService.getMyTeachers(this.currentTeachersPage);

      this.myTeachers.push(...myTeachers?.teachers);

      this.totalTeachers = myTeachers?.total;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os professores.',
        status: 'error'
      });

      this.errorMyTeachers = true;

      console.error(error);
    } finally {
      this.isLoadingMyTeachers = false;
    }
  }

  showMoreUsers() {
    if (!this.hasMoreUsers) return;

    if (this.showingStudents) {
      this.currentClassPage += 1;
      this.getMyClass();
      return;
    }

    this.currentTeachersPage += 1;
    this.getMyTeachers();
  }

  setShowingStudents(option: number) {
    if (option === tabTeachers) {
      this.showingStudents = false;
      this.currentUserList = this.myTeachers;
      return;
    }

    this.showingStudents = true;
    this.currentUserList = this.myClass;
  }

  getUserName(user: Record<string, any>) {
    if (this.showingStudents) return user.name;

    return `${user.name} | ${user.discipline}`;
  }
}
