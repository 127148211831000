

































































import { Component, Vue } from 'vue-property-decorator';

import SubjectBox from '@/components/Subject/SubjectBox/index.vue';

import ContainerGraphic from './components/ContainerGraphic/index.vue';
import TableHistoricAccess from './components/TableHistoricAccess/index.vue';

import LoadingMyPerformance from './LoadingMyPerformance.vue';

import { DATA_CARD, CONTEXTS } from './constants';
import { LIST_MONTHS } from '@/constant/Dates';

import StatsService from '@/services/Stats/StatsService';

import { StatsStudent, Stats, DataGetStudent } from './interface';
import { currentMonth, currentYear } from '@/share/Util/Dates';

@Component({
  components: {
    SubjectBox,
    ContainerGraphic,
    TableHistoricAccess,
    LoadingMyPerformance
  }
})
export default class MyPerformance extends Vue {
  private DATA_CARD = DATA_CARD;

  private dateSelectedVideos: DataGetStudent = { month: null, year: null };
  private dateSelectedMaterials: DataGetStudent = { month: null, year: null };
  private dateSelectedExercises: DataGetStudent = { month: null, year: null };
  private dateSelectedAccess: DataGetStudent = { month: null, year: null };

  private studentDataVideos: StatsStudent | {} = {};
  private studentDataMaterials: StatsStudent | {} = {};
  private studentDataExercises: StatsStudent | {} = {};
  private studentDataAccess: Stats | Record<string, any> = {};

  private isLoadingStudentVideos = true;
  private isLoadingStudentMaterials = true;
  private isLoadingStudentExercises = true;
  private isLoadingStudentAccess = true;
  private isLoadingStudentData = true;

  private StatsService = new StatsService();

  created() {
    this.getStudentByContext();
  }

  get isLoadingContext() {
    return {
      [CONTEXTS.VIDEOS]: this.isLoadingStudentVideos,
      [CONTEXTS.EXERCISES]: this.isLoadingStudentExercises,
      [CONTEXTS.MATERIALS]: this.isLoadingStudentMaterials,
      [CONTEXTS.ACCESS]: this.isLoadingStudentAccess
    };
  }

  get dateSelectedContex() {
    return {
      [CONTEXTS.VIDEOS]: this.dateSelectedVideos,
      [CONTEXTS.EXERCISES]: this.dateSelectedExercises,
      [CONTEXTS.MATERIALS]: this.dateSelectedMaterials,
      [CONTEXTS.ACCESS]: this.dateSelectedAccess
    };
  }

  get dataContext(): Record<string, any> {
    return {
      [CONTEXTS.VIDEOS]: this.studentDataVideos,
      [CONTEXTS.EXERCISES]: this.studentDataExercises,
      [CONTEXTS.MATERIALS]: this.studentDataMaterials,
      [CONTEXTS.ACCESS]: this.studentDataAccess
    };
  }

  async getStudentByContext(data?: DataGetStudent) {
    try {
      switch (data?.context) {
        case CONTEXTS.VIDEOS:
          if (
            this.dateSelectedVideos.month === data.month
            && this.dateSelectedVideos.year === data.year
          ) return;

          this.isLoadingStudentVideos = true;
          this.studentDataVideos = await this.getStudent(data);
          this.dateSelectedVideos.month = data.month;
          this.dateSelectedVideos.year = data.year;
          break;

        case CONTEXTS.EXERCISES:
          if (
            this.dateSelectedExercises.month === data.month
            && this.dateSelectedExercises.year === data.year
          ) return;

          this.isLoadingStudentExercises = true;
          this.studentDataExercises = await this.getStudent(data);
          this.dateSelectedExercises.month = data.month;
          this.dateSelectedExercises.year = data.year;
          break;

        case CONTEXTS.MATERIALS:
          if (
            this.dateSelectedMaterials.month === data.month
            && this.dateSelectedMaterials.year === data.year
          ) return;

          this.isLoadingStudentMaterials = true;
          this.studentDataMaterials = await this.getStudent(data);
          this.dateSelectedMaterials.month = data.month;
          this.dateSelectedMaterials.year = data.year;
          break;

        case CONTEXTS.ACCESS:
          if (
            this.dateSelectedAccess.month === data.month
            && this.dateSelectedAccess.year === data.year
          ) return;

          this.isLoadingStudentAccess = true;
          // eslint-disable-next-line no-case-declarations
          const { access } = await this.getStudent(data);
          this.studentDataAccess = access;
          this.dateSelectedAccess.month = data.month;
          this.dateSelectedAccess.year = data.year;
          break;

        default:
          this.isLoadingStudentData = true;
          // eslint-disable-next-line no-case-declarations
          const studentData = await this.getStudent(data);

          this.studentDataVideos = studentData;
          this.studentDataExercises = studentData;
          this.studentDataMaterials = studentData;
          this.studentDataAccess = studentData.access;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: `Erro ao carregar os dados ${data?.context ? `de ${data?.context}` : ''}`,
        status: 'error'
      });
      console.error(error);
    } finally {
      this.isLoadingStudentVideos = false;
      this.isLoadingStudentExercises = false;
      this.isLoadingStudentMaterials = false;
      this.isLoadingStudentAccess = false;
      this.isLoadingStudentData = false;
    }
  }

  async getStudent(data?: DataGetStudent) {
    const { idstudent } = this.$store.getters.profile;
    let formattedMonth = '';

    if (data?.month) {
      formattedMonth = Number(data?.month) < 10 ? `0${data?.month}` : `${data?.month}`;
    }

    const student = await this.StatsService.getStudent({
      userId: String(idstudent),
      month: formattedMonth || undefined,
      year: data?.year || undefined
    });

    return student;
  }

  isAccessHistoryCard(indexCard: number) {
    const indexAccessHistoryCard = DATA_CARD.length - 1;
    return indexCard === indexAccessHistoryCard;
  }

  getTotalValueFromContext(card: Record<string, any>, index: number) {
    const totalContentVisited = this.dataContext[card.contextLocal][card.contextBack]?.total;
    const totalAccessPlatform = this.dataContext[card.contextLocal]?.total;

    if (!this.isAccessHistoryCard(index)) {
      return totalContentVisited >= 0 ? totalContentVisited : '-';
    }

    return totalAccessPlatform >= 0 ? totalAccessPlatform : '-';
  }

  getFormattedDateFromContext(card: Record<string, any>) {
    switch (card.contextLocal) {
      case CONTEXTS.VIDEOS:
        return this.dateSelectedVideos.month
          ? `${LIST_MONTHS[this.dateSelectedVideos.month - 1].title}/${this.dateSelectedVideos
            .year || currentYear}`
          : `${LIST_MONTHS[Number(currentMonth) - 1].title}/${this.dateSelectedVideos.year
              || currentYear}`;

      case CONTEXTS.EXERCISES:
        return this.dateSelectedExercises.month
          ? `${LIST_MONTHS[this.dateSelectedExercises.month - 1].title}/${this.dateSelectedExercises
            .year || currentYear}`
          : `${LIST_MONTHS[Number(currentMonth) - 1].title}/${this.dateSelectedExercises.year
              || currentYear}`;

      case CONTEXTS.MATERIALS:
        return this.dateSelectedMaterials.month
          ? `${LIST_MONTHS[this.dateSelectedMaterials.month - 1].title}/${this.dateSelectedMaterials
            .year || currentYear}`
          : `${LIST_MONTHS[Number(currentMonth) - 1].title}/${this.dateSelectedMaterials.year
              || currentYear}`;

      default:
        return this.dateSelectedAccess.month
          ? `${LIST_MONTHS[this.dateSelectedAccess.month - 1].title}/${this.dateSelectedAccess
            .year || currentYear}`
          : `${LIST_MONTHS[Number(currentMonth) - 1].title}/${this.dateSelectedAccess.year
              || currentYear}`;
    }
  }
}
