



































import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import DataFilters from '@/components/DataFilters/index.vue';
import DataTable from '@/components/DataTable/index.vue';
import ExLoading from '@/globalComponents/ExLoading/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import { currentMonth, currentYear } from '@/share/Util/Dates/index';

import { Dates } from '../../interface';

interface HeaderTable {
  title: string;
}

const USER_ACCESSED = 1;

@Component({
  components: {
    DataFilters,
    DataTable,
    ExLoading,
    FeedbackUser
  }
})
export default class TableHistoricAccess extends Vue {
  @Prop() context!: string;
  @Prop() month!: number;
  @Prop() year!: number;
  @Prop() tableHeader!: Array<HeaderTable>;
  @Prop() dataTableBody!: Dates;
  @Prop({ default: true }) isLoading!: boolean;

  private selectedMonth = Number(currentMonth);
  private selectedYear = Number(currentYear);

  created() {
    this.saveLastFilteredDate();
  }

  get filterAccessDates() {
    const accessValue = Object.values(this.dataTableBody);
    const filterAccessDates: string[] = Object.keys(this.dataTableBody).filter(
      (date: string, index: number) => accessValue[index] === USER_ACCESSED
    );

    filterAccessDates.sort((a: string, b: string) => new Date(a).getTime() - new Date(b).getTime());

    return filterAccessDates;
  }

  @Watch('selectedMonth')
  @Watch('selectedYear')
  getChangeValuesSelects() {
    if (this.selectedMonth > 0 && this.selectedYear > 0) {
      this.emitSelectedDate();
    }
  }

  emitSelectedDate() {
    this.$emit('get-data-by-context', {
      context: this.context,
      month: this.selectedMonth,
      year: this.selectedYear
    });
  }

  getSelectedMonth(month: string) {
    if (Number(month) === this.selectedMonth) return;

    this.selectedMonth = Number(month);
  }

  getSelectedYear(year: number) {
    if (year === this.selectedYear) return;

    this.selectedYear = year;
  }

  formattedDate(date: string) {
    const dateSplit = date.split('-');
    const newDate = `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;
    return newDate;
  }

  formattedMonthForFilter() {
    return this.selectedMonth < 10 ? `0${this.selectedMonth}` : `${this.selectedMonth}`;
  }

  saveLastFilteredDate() {
    this.selectedMonth = this.month || this.selectedMonth;
    this.selectedYear = this.year || this.selectedYear;
  }
}
