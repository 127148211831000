






































import { Component, Vue } from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';

import Modal from '@/globalComponents/Modal/Modal.vue';

import PhotoProfile from './components/PhotoProfile/PhotoProfile.vue';
import PersonalData from './components/PersonalData/PersonalData.vue';
import ConsentTerms from './components/ConsentTerms/ConsentTerms.vue';
import ModalMyData from './components/ModalMyData/index.vue';

import ProfileService from '@/services/Profile/ProfileService';
import AuthService from '@/services/Auth/AuthService';

import ActiveModal from '@/share/Util/ActiveModal';
import { setNumber } from '@/share/Util/UsefulFunctions/UserfulFunctions';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';
import { LIST_AVATAR } from './constants/listAvatar';

interface Terms {
  name: string;
  checked: boolean;
}

@Component({
  components: {
    PhotoProfile,
    Modal,
    PersonalData,
    ConsentTerms,
    ModalMyData,
    Loading
  }
})
export default class MyData extends Vue {
  private isErrorRequest = false;
  private isLoading = false;

  private isSaveImage = 0;
  private isUpdated = false;

  private setModal = new ActiveModal();
  private ProfileService = new ProfileService();
  private AuthService = new AuthService();

  private defaultData = {
    name: this.$store.getters.profile.name,
    nickname: this.$store.getters.profile.nickname,
    birthday: this.formatBirthday(this.$store.getters.profile.birthday),
    gender: this.$store.getters.profile.gender,
    avatar: this.$store.getters.profile.avatar || LIST_AVATAR[0].image,
    email: this.$store.getters.profile.email,
    ddd: this.phone.ddd || null,
    phone: this.phone.phone || null,
    isStudent: Number(this.$store.getters.profile.institution?.is_studying)
  };

  private personalData = JSON.parse(JSON.stringify(this.defaultData));

  private consentTerms = {
    consentPersonalDataUse: this.$store.getters.profile.consentPersonalDataUse || false,
    acceptsPromotions: this.$store.getters.profile.acceptsPromotions || false,
    acceptsTips: this.$store.getters.profile.acceptsTips || false
  };

  get phone() {
    if (this.$store.getters.profile.phone?.includes('(')) {
      return {
        ddd: this.$store.getters.profile.phone?.slice(1, 3).trim(),
        phone: this.$store.getters.profile.phone?.slice(4).trim()
      };
    }

    return {
      ddd: this.$store.getters.profile.phone?.slice(0, 2).trim(),
      phone: this.$store.getters.profile.phone?.slice(2).trim()
    };
  }

  clearForm() {
    this.personalData = this.copyDefaultData();
  }

  copyDefaultData() {
    return JSON.parse(JSON.stringify(this.defaultData));
  }

  setConsentTerms(checkBoxTerms: Terms) {
    // @ts-ignore
    this.consentTerms[checkBoxTerms.name] = checkBoxTerms.checked;
  }

  changeAvatar(avatar: string) {
    this.personalData.avatar = avatar;
  }

  async updateData() {
    try {
      this.isLoading = true;

      const content = {
        name: this.personalData.name,
        nickname: this.personalData.nickname,
        birthday: this.personalData.birthday,
        gender: Number(this.personalData.gender),
        avatar: this.personalData.avatar,
        ddd: this.personalData.ddd,
        phone: this.personalData.phone,
        isStudent: Number(this.personalData.isStudent),
        aceite_receberPromocoes: Number(this.consentTerms.acceptsPromotions),
        aceite_receberDicas: Number(this.consentTerms.acceptsTips),
        consent_usoDadosPessoais: Number(this.consentTerms.consentPersonalDataUse)
      };

      const response = await this.ProfileService.updateUser(content);

      if (response && response?.status === STATUS_RESPONSE_API.SUCCESS_OK) {
        this.isErrorRequest = false;
        this.setModal.activeModal('modalProfileData');

        this.$store.dispatch('setProfile', {
          ...this.$store.getters.profile,
          ...content,
          phone: `${content.ddd} ${content.phone}`,
          institution: {
            ...this.$store.getters.profile.institution,
            is_studying: content.isStudent
          },
          acceptsPromotions: content.aceite_receberPromocoes,
          acceptsTips: content.aceite_receberDicas,
          consentPersonalDataUse: content.consent_usoDadosPessoais
        });

        this.isSaveImage += 1;
        this.isUpdated = true;
      }
    } catch (error) {
      this.isErrorRequest = true;
      this.setModal.activeModal('modalProfileData');
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  formatBirthday(date: string) {
    const newDate = new Date(date);
    const day = setNumber(newDate.getUTCDate());
    const month = setNumber(newDate.getUTCMonth() + 1);
    const year = newDate.getUTCFullYear();

    return `${year}/${month}/${day}`;
  }
}
